
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IEmployee, ActionType } from "@/store/modules/employees";

@Component
export default class EmployeeList extends Vue {
  @Prop({ required: true, type: Array }) private readonly employees!: IEmployee[];
  @Prop({ required: false, type: Number, default: ActionType.NONE }) private readonly actionType!: ActionType;

  get btnLabel(): string
  {
    return this.actionType === ActionType.DISMISS ? '+' : '-';
  }

  moveEmployee(employee: IEmployee): void
  {
    this.$emit('moveEmployee', employee, this.actionType);
  }
}
