












import { Component, Vue } from "vue-property-decorator";
import {ActionType} from '@/store/modules/employees';

@Component
export default class App extends Vue {  
  get actionType(): typeof ActionType {
    return ActionType;
  }
}
