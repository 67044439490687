

































import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store/modules/employees";
import { IEmployee, ActionType } from "@/store/modules/employees";
import EmployeeList from "@/components/EmployeeList.vue";

@Component({
  components: {
    EmployeeList
  }
})
export default class Home extends Vue {
  store = getModule(store, this.$store);
  search = '';

  get error(): string {
    return this.$store.state.employees.error;
  }

  get isLoading(): boolean {
    return this.$store.state.employees.isLoading;
  }

  get actionType(): typeof ActionType {
    return ActionType;
  }

  get filtertedEmployees(): IEmployee[] {
    return this.store.filtertedEmployees(this.search);
  }

  get laidOffWorkers(): IEmployee[] {
    return this.store.laidOffWorkers;
  }

  get isLaidOffWorkersShown(): boolean {
    return !!this.laidOffWorkers.length;
  }

  get numberOfPeopleLaidOff(): number {
    return this.laidOffWorkers.length;
  }

  moveEmployee(employee: IEmployee, actionType: ActionType): void {
    actionType === ActionType.DISMISS ? this.dismissEmployee(employee) : this.restoreEmployee(employee);
  }

  dismissEmployee(employee: IEmployee): void {
    this.store.dismissEmployee(employee);
  }

  restoreEmployee(employee: IEmployee): void {
    this.store.restoreEmployee(employee);
  }

  getEmployees(): void {
    this.store.getEmployees();
  }

  created(): void {
    this.getEmployees();
  }
}
